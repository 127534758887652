<template>
  <div class="h-full">
    <Scrollbar>
      <Table :auto-loading="true" :options="handleOptions" :loadData="handleLoadData" title="系统评语库" ref="table">
        <template #action>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="handleCreate">添加</el-button>
            <el-button  @click="handleLimit">评语字数限制</el-button>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from "./components/libraryEdit.vue";
import limit from "./components/libraryMax.vue";
export default {
  name: "panel",
  components: {
    edit
  },
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {},
    };
  },
  methods: {
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/comment/library").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.page("comment/list", {size, page}, Object.assign(this.formSearch,search)).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
  
    handleLimit(){
      this.$cloud.dialog({
        title: '评语字数限制',
        data: {},
        component: limit,
        success: '保存成功!',
        warning: '保存失败!',
      })
    },

    //添加评语
    handleCreate(){
      this.$cloud.dialog({
        title: ' 添加系统评语',
        data: {},
        component: edit,
        success: '添加成功！',
        warning: '添加失败！',
        customClass: 'custom-class-library',
        refresh: this.handleRefresh
      })
    },

    handleUpdate(row){
      this.$cloud.dialog({
        title: '更新系统评语',
        data: row,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        customClass: 'custom-class-library',
        refresh: this.handleRefresh
      })
    },

    handleDelete(item){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("comment/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },


    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss">
.custom-class-library{
    width: 650px;
}
</style>
