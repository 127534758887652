<!--
 * ======================================
 * 说明： 新增修改弹窗
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/8/4 21:58
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
    <el-form :model="editForm" ref="editForm" :rules="rules" label-width="100px">
        <el-form-item label="评语类型" prop="type">
          <el-radio-group v-model="editForm.type">
            <el-radio label="system">系统评语</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学生性别" prop="sex">
          <el-radio-group v-model="editForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="评语" prop="content">
            <el-input type="textarea"  :rows="6" v-model="editForm.content"></el-input>
        </el-form-item>
    </el-form>
</template>
<script>
  export default {
    name: 'libraryEdit',
    props: {
      value:Object,
    },
    data() {
      return {
        editForm:{
          type:'system',
          sex:1,
          content:'',
        },
        rules:{
          content:[
            { required: true, message: '请输入评语', trigger: 'blur' },
          ]
        }
      }
    },
    computed: {},
    created() {},
    mounted() {
      if(this.value.id){
        this.handleLoadForm(this.value.id)
      }else{
        this.editForm={
            type:'system',
            sex:1,
            content:'',
        }
      }
    },
    methods: {
      handleLoadForm(id){
        this.$cloud.get('/comment/detail',{id}).then(res=>{
          this.editForm = res
        })
      },
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['editForm'].validate((valid)=>{
            if(valid){
              let api = this.editForm.id ? '/comment/edit' : '/comment/create'
              this.$cloud.post(api,this.editForm).then(()=>{
                resolve(true)
              }).catch(()=>{
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>