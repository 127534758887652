<!--
 * ======================================
 * 说明： 设置科目老师的评语字数限制
 * 作者： Silence
 * 文件： libraryMax.vue
 * 日期： 2023/9/18 22:09
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="editForm" :model="editForm" :rules="rules" label-width="100px">
    <el-form-item label="评语科目" prop="id">
      <el-select v-model="editForm.id" @change="handleChangeSubject">
        <el-option
          v-for="item in subjectOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="字数限制" prop="max">
      <el-input-number style="width: 190px" v-model="editForm.max" :min="0"></el-input-number>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        subjectOptions:[],
        editForm:{
          max:0,
          id:null,
        },
        rules:{
          max:[{ required: true, message: '请输入评语字数限制', trigger: 'blur' }],
          id:[{ required: true, message: '请选择评语科目', trigger: 'blur' }]
        }
      }
    },
    computed: {},
    created() {},
    mounted() {
      this.handleLoadSubject();
    },
    methods: {
      handleLoadSubject(){
        this.$cloud.get('subject/list',{
          page:1,
          size:99999,
        }).then(res=>{
          this.subjectOptions = res.children
          if(res.children.length){
            this.editForm.id = res.children[0].id
            this.handleChangeSubject()
          }
        })
      },
      
      // 获取限制字数
      handleChangeSubject(){
        this.$cloud.get('subject/detail',{id:this.editForm.id}).then(res=>{
          this.editForm.max = res.max || 0
        })
      },
  
  
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['editForm'].validate((valid)=>{
            if(valid){
                this.$cloud.post('subject/max',this.editForm).then(()=>{
                  resolve(true)
                }).catch(()=>{
                  reject(false)
                })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>